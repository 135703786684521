import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { LoaderComponent } from './loader/loader.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { currencyWithoutDecimalPipe } from '../core/pipe/inr-currency.pipe';
import { SearchPipe } from '../core/pipe/search.pipe';
import { CustomPillsComponent } from './custom-pills/custom-pills.component';
import { CustomAccordionComponent } from './custom-accordion/custom-accordion/custom-accordion.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PrimengModule } from './primeng/primeng.module';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { AlertMessagesComponent } from './dialogs/alert-messages/alert-messages.component';

@NgModule({
  declarations: [
    LoaderComponent,
    currencyWithoutDecimalPipe,
    SearchPipe,
    CustomPillsComponent,
    CustomAccordionComponent,
    NoDataFoundComponent,
    SkeletonComponent,
    AlertMessagesComponent,

  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],

  imports: [
    CommonModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    PrimengModule
    // RouterModule.forRoot([])
  ],
  exports: [
    MaterialModule,
    LoaderComponent,
    currencyWithoutDecimalPipe,
    SearchPipe,
    CustomPillsComponent, CustomAccordionComponent,
    NoDataFoundComponent,
    PrimengModule,
    SkeletonComponent, 
    AlertMessagesComponent
  ]
})
export class SharedModule { }
