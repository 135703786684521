/**
 * Error message class containing most used error messages
 */
 export class ErrorMessages {

    required = (type: string) => {
        return `${type} is required`;
    }

    invalid = (type: string) => {
        return `${type} is invalid`;
    }

    minLength = (length: number) => {
        return `Minimum ${length} characters are required`;
    }

    maxLength = (length: number) => {
        return `Maximum ${length} characters are allowed`;
    }

    minValue = (value: number) => {
        return `Minimum value is ${value.toLocaleString('en-IN')}`;
    }

    maxValue = (value: number) => {
        return `Maximum value is ${value.toLocaleString('en-IN')}`;
    }

    dropdown = (type: string) => {
        return `Please select ${type}`;
    }

    multiselect = (type: string) => {
        return `Please select at least one ${type}`;
    }

    fileSize = (value: number) => {
        return `File should not exceed ${value} MB`;
    }

    fileFormat = 'Invalid file format';

    alphabetOnly = 'Should only contain alphabets';

    alphaNumeric = 'Should be alpha numeric';

    passwordPattern = 'Password must contain 1 uppercase & lowercase, 1 digit and 1 symbol';

    matchWithOldPassword = 'New password should not be same as old password';

    notMatchWithConfirmPassword = 'Password do not match';
}

/**
 * Data to save in local storage
 */
export const DATA_TO_SAVE = [
    'first_name',
    'last_name',
    'email',
    'status',
    'profile_pic',
    'user_id',
    'user_availability'
];

/**
 * Constant that generates array of years
 * @param year `(optional)` Maximum year value in list
 * @returns Array of years in string type
 */
export const yearList = (year?: number) => {
    let currentYear = new Date().getFullYear();
    let array = Array((year || currentYear) - 1980 + 1).fill(1).map((_, idx) => (year || currentYear) - idx);
    console.log(array);
    
    return array.map(x => x.toString());

}

/**
 * Array of months with their indices starting from 1
 */
export const monthList = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
];

/**
 * Array of Degree
 */
export const DEGREE = [
    "Bachelors (or equivalent)",
    "Doctorate (or equivalent)",
    "MBA (or equivalent)",
    "Masters (or equivalent)",
    "Secondary/High School (or equivalent)",
    "Other degree"
];

/**
 * Array of Documnents
 */
 export const Documents = [
    "Aadhar Card",
    "Pan Card",
    "Salary Slip",
    "Releving Letter"
];

/**
 * List of user's interview availability
 */
export const PREFERENCE = [
    {
        label: 'Remote',
        value: 'remote',
    },
    {
        label: 'On-site/Hybrid',
        value: 'On-site/Hybrid',
    },
    {
        label: 'Both',
        value: 'Both',
    }
];

/**
 * Array of user communication skill
 */
export const COMMUNICATION_SKILL = [
    {
        label: 'excellent',
        content: 'I am fluent in both verbal and written communication. I can communicate with US teams efficiently without need for repetition.'
    },
    {
        label: 'good',
        content: 'I can communicate fluently in written form. Verbal communication with US teams is possible when speaking slowly on either side. There may be a need for repetition occasionally.'
    },
    {
        label: 'average',
        content: 'I can communicate fluently in written form over email and other text communication tools. Verbal communication with US teams can be slow at times.'
    }
];

/**
 * Array of user time zone option
 */
export const TIME_ZONE = [
    {
        label: 'any_time',
        content: 'I’m open to work for flexible shifts with any Time Zone',
    },
    {
        label: 'time_zone',
        content: 'I’d prefer to work for shifts compatible with My Time Zone'
    }
];

export const FOUND_US = [
    'Linkedin', 'Facebook', 'Job Search on Google', 'Referral', 'Word of mouth', 'Community Events', 'Online News'
]