import { createAction, props } from "@ngrx/store";
import { User } from "../../services/models/user.model";

export const LOGIN_START = '[auth page] login start';
export const LOGIN_SUCCESS = '[auth page] login success';
export const  LOGIN_FAIL = '[auth page] login fail';

export const AUTO_LOGIN_ACTION = '[auth page] auto login';
export const LOGOUT_ACTION = '[auth page] logout';


export const loginStart = createAction(
    LOGIN_START, 
    props<{ email:string, password:string }>()
);

export const loginSuccess = createAction(
    LOGIN_SUCCESS,
    props<{ user: any | null, redirect: boolean }>()
);
export const loginSuccessAuto = createAction(
    LOGIN_SUCCESS,
    props<{ user: any | null, redirect: boolean }>()
);

export const loginFail = createAction(
    LOGIN_FAIL,
    
);

export const autoLogin = createAction(
    AUTO_LOGIN_ACTION
);

export const autoLogout = createAction(
    LOGOUT_ACTION
);
