import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class ApiService {
  public userData: any;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) { }

  private extractData(res: any) {
    return res || {};
  }

  // get(endpoint: string, url: any): Observable<any> {
  //   return this.http.get<any>(endpoint + url)
  //     .pipe(
  //       map(this.extractData),
  //       catchError(this.handleError)
  //     );
  // }
  get(endpoint: string, url: any, queryParams?: any): Observable<any> {
    let params = new HttpParams();
    if (queryParams) {
      for (let key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          params = params.append(key, queryParams[key]);
        }
      }
    }
    return this.http.get<any>(endpoint + url, { params: params })
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }
  post(endpoint: string, url: any, body: any): Observable<any> {
    return this.http.post<any>(endpoint + url, body)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }

  put(endpoint: string, url: any, body?: any): Observable<any> {
    return this.http.put<any>(endpoint + url, body)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }

  delete(endpoint: string, url: any): Observable<any> {
    return this.http.delete<any>(endpoint + url)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {

    let errorMessage = "";
    errorMessage = `Error :  ${error.error.message}`;
    // if (error.error instanceof ErrorEvent) {
    //   // client-side error
    //   errorMessage = `Error: ${error.error.message}`;
    // } else {
    //   // server-side error
    //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    // }
    return throwError(errorMessage);
  }

  headerCount(url: any): Promise<any> {
    return this.http.get(url).toPromise().then((res: any) => {
      return res;
    }).catch((error: any) => {
      console.error('An error occurred:', error);
      throw error;
    });
  }
}
