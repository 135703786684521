import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ALL_ROUTES } from './core/routes/all-routes';
import { RedirectComponent } from './standalone/redirect/redirect.component';
import { ProjectLayoutComponent } from './core/layouts/project-layout/project-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('../app/auth/auth.module').then(m => m.AuthModule)
},
  {
    path: '',
    component: ProjectLayoutComponent,
    children: ALL_ROUTES,
    // canActivate: [AuthGaurd]
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
];
// { preloadingStrategy: PreloadAllModules }

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
