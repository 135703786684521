import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Subject, timeout } from 'rxjs';
import { io, Socket } from "socket.io-client";
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';


@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket | null = null;
  private base: string = environment.socket_base_url;

  notifications = new Subject<any>();
  notificationCount = new Subject<number>();
  actionNotifications = new Subject<any>();
  actionNotificationCount = new Subject<number>();

  updateActionNotificationCount = new BehaviorSubject<any>(null);

  constructor(
    private _http: HttpClient,
    private _utility: UtilityService
  ) { }
  connect(): void {
    const userDetails = JSON.parse(localStorage.getItem('userData') || '{}');
    if (!userDetails || !userDetails.token) {
      console.error('No user token found in local storage');
      return;
    }

    const token = userDetails.token.replace('Bearer ', '');

    this.socket = io(this.base, {
      query: {
        token: token
      },
      reconnection: false,
    });

    // this.socket.on('connect', () => {
    //   console.log('Connected to the server');
    // });

    this.socket.on('connect_error', (error: any) => {
      console.error('Connection error:', error);

      if (error.message.includes('400')) {
        this.unsubscribe();
      }
    });

    // Handle chat notifications
    this.socket.emit('checkChatMessageAdmin', JSON.stringify(userDetails));
    this.socket.on('chatNotificationUpdateAdmin', (data: any) => {
      this.notifications.next(data);
      this.notificationCount.next(data?.chatNotificationCount);
      // console.log('chatNotificationUpdateAdmin', data);
    });

    // Handle action notifications
    this.socket.emit('checkJobNotificationAdmin', JSON.stringify(userDetails));
    this.socket.on('getJobNotificationAdmin', (data: any) => {
      this.actionNotifications.next(data);
      this.actionNotificationCount.next(data?.notificationCount);
      // console.log('getJobNotificationAdmin', data);
    });
  }

  unsubscribe(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
  updateMessageCount() {
    const userDetails = JSON.parse(localStorage.getItem('userData') || '');
    const token = userDetails?.token.replace('Bearer ', '');  // GET local storage token
    this.socket = io(this.base + "?token=" + token); // Connect with socket
    let data = {
      token: userDetails?.token
    }
    this.socket.emit('checkChatMessageCountAdmin', JSON.stringify(data));
    this.socket.on('chatNotificationCountUpdateAdmin', (data: any) => {
      this.notifications.next(data);
      this.notificationCount.next(data?.chatNotificationCount)
    });
  };

  /**
   * notificationsList();
   * @param queryParams
   * @returns Observable
   * @description This function are provide notification list
   */
  notificationsList(queryParams?: any) {
    let query = queryParams ? this._utility.returnQueryParams(queryParams) : '';
    return this._http.get(`${this.base}api/v1/chat/admin-notification-list${query}`).pipe(timeout(40000), catchError((error: HttpErrorResponse) => {
      throw error;
    }));
  };

  /**
   *
   * @param queryParams
   * @returns Observable
   * @description This function are provide action notification list
   */
  actionNotificationList(queryParams?: any) {
    let query = queryParams ? this._utility.returnQueryParams(queryParams) : '';
    return this._http.get(`${this.base}api/v1/notification/notification-list${query}`).pipe(timeout(40000), catchError((error: HttpErrorResponse) => {
      throw error;
    }));
  };

  readMessage(id: string) {
    return this._http.get(`${this.base}api/v1/notification/read-notification-status/${id}`).pipe(timeout(40000), catchError((error: HttpErrorResponse) => {
      throw error;
    }));
  };

  /**
   * @description This function are mark all notification as read
   * @returns {Observable} `Observable`
   */
  markAllAsActionNotificationsRead() {
    return this._http.get(`${this.base}api/v1/notification/read-all-notification-status`).pipe(timeout(40000), catchError((error: HttpErrorResponse) => {
      throw error;
    }));
  };

  /**
   * @description This function are mark all chat notification as read
   * @returns {Observable} `Observable`
   */
  markAllAsCahtNotificationsRead() {
    return this._http.get(`${this.base}api/v1/chat/read-all-chat-notification-status`).pipe(timeout(40000), catchError((error: HttpErrorResponse) => {
      throw error;
    }));
  };

}
