
import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription, distinctUntilChanged, takeUntil } from 'rxjs';

import { MENU_ITEMS } from '../../../side-nav-menu';
import { UtilityService } from '../../services/utility.service';
import { AppState } from '../../store/app.state';
import { isAuthenticated } from '../../store/auth-state/auth.selector';
import { SocketService } from '../../services/socket.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuList = MENU_ITEMS;
  queryParams: any;
  currentRoute!: string;
  loggedInUserDetails: any;
  isAuthenticated: Observable<boolean> | undefined;
  profilePic!: string;
  userName!: string;
  role!: string;
  permissions!: string;
  userDetailsSubscription!: Subscription;
  notificationCount: number = 0;
  notifications: any;
  actionNotificationCount: number = 0;
  actionNotifications: any;
  maxLength: number = 80; // Set your desired maximum length
  showFull: boolean = false;
  element: any;
  currentURL: string = environment?.currentEnv;
  private _unsuscribe$ = new Subject<boolean>();
  // action
  constructor(
    public router: Router,
    private store: Store<AppState>,
    public utility: UtilityService,
    private _activatedRoute: ActivatedRoute,

    // private _websocket: WebservicesService,
    public _socket: SocketService
  ) { }
  ngOnInit(): void {
    this._socket.connect();

    // Get Chat notification List
    this._socket.notifications.pipe(takeUntil(this._unsuscribe$)).subscribe({
      next: (res: any) => {
        this.notifications = res?.chatNotification;
      }
    })

    // Get Chat notification count
    this._socket.notificationCount.pipe(distinctUntilChanged(), takeUntil(this._unsuscribe$)).subscribe({
      next: (res: any) => {
        this.notificationCount = res;
      }
    })

    // Get actions notification List
    this._socket.actionNotifications.pipe(distinctUntilChanged(), takeUntil(this._unsuscribe$)).subscribe({
      next: (res: any) => {
        this.actionNotifications = res?.notificationData;
        // console.log('this.actionNotifications: ', this.actionNotifications);
      }
    })

    // Get actions notification count
    this._socket.actionNotificationCount.pipe(distinctUntilChanged(), takeUntil(this._unsuscribe$)).subscribe({
      next: (res: any) => {
  
        this.actionNotificationCount = res;
        // console.log('this.actionNotificationCount: ', this.actionNotificationCount);
      }
    })


    this.loggedInUserDetails = JSON.parse(localStorage.getItem('userData') || '{}');
    let user = {
      ...this.loggedInUserDetails,
    }
    this.utility.updatedUserDetails(
      user
    );
     this.utility.userDetails.pipe(distinctUntilChanged(), takeUntil(this._unsuscribe$)).subscribe((details) => {
      if (details) {
        this.userName = details.first_name + ' ' + details.last_name ? details.first_name + ' ' + details.last_name : this.loggedInUserDetails.first_name + ' ' + this.loggedInUserDetails.last_name;
        this.profilePic = details.profile_pic ? details.profile_pic : this.loggedInUserDetails.profile_pic;
        this.role = details.role[0].role_slug;
        this.permissions = details?.permissions;
      }
    });
    this.currentRoute = this.router.url;
    this.isAuthenticated = this.store.select(isAuthenticated);
  }

  // routerWithQueryParams(routeLink: string, queryParams?: any) {
  //   this.queryParams = queryParams;

  //   if (this.router.url.split('?')[0] != routeLink) {
  //     if (routeLink == '/individual-developer' && this.role == "talent management associate") {
  //       this.router.navigate(['/individual-developer/benchpool-list'], { queryParams: { offset: 0, limit: 10, pool_status: 'benchpool' } });
  //     } else {
  //       this.router.navigate([routeLink], { queryParams: this.queryParams });
  //     }
  //   }
  // }
  navigateToChat(notification: any) {
    const projectId = this.utility.base64Encode(notification?.project_id);

    this.router.navigate(['/chat/', projectId, 'redirect'], { queryParams: { param1: 'value1', param2: 'value2' } });


    this.router.navigate(['/chat/', projectId], {
      relativeTo: this._activatedRoute,
      queryParams: {
        vendor_id: notification.sender_admin_id,

      }
    });
  }
  // logout(event: Event) {
  //   event.preventDefault();
  //   this.store.dispatch(autoLogout());
  // }



  dateAgo(value: any, ...args: unknown[]): unknown {
    if (!value) { return 'a long time ago'; }
    let time = (Date.now() - Date.parse(value)) / 1000;
    if (time < 10) {
      return 'Just Now';
    } else if (time < 60) {
      return 'Just Now';
    }
    const divider = [60, 60, 24, 30, 12];
    const string = [' second', ' minute', ' hour', ' day', ' month', ' year'];
    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }
    const plural = Math.floor(time) > 1 ? 's' : '';
    return Math.floor(time) + string[i] + plural + ' ago';
  }


  toggleReadMore() {
    this.showFull = !this.showFull;
  }


  // Navigate to action notification
  navigateToActionNotification(notification?: any) {
    this.readMessage(notification?._id);
    let index = this.actionNotifications.findIndex((x: any) => x?._id == notification?._id);
    this.actionNotifications[index]['read_status'] = true;
    if (notification?.moved_to_next_status) return
    let url: any = this.checkStatus(notification);
    window.open(url, '_self');
  }

  // Navigate URLS
  checkStatus(notification: any) {
    const statusMap: { [key: string]: string } = {
      'DEVELOPER_SUBMITTED_TO_SALES': '?status=client-submitted',
      'DEVELOPER_REJECTED': '?status=rejected',
      'DEVELOPER_SHORTLISTED': '?status=shortlisted',
      'DEVELOPER_ONBOARDED': '?status=accepted',
      'DEVELOPER_HIRED': '?status=selected',
      'NEW_JOB_POSTED': '?appliedTab=true',
      'JOB_MODIFIED': '?appliedTab=true',
      'SCHEDULED_INTERVIEW': `?offset=0&status=interview&${notification?.interview_round_no ? `round_no=${notification?.interview_round_no}` : ''}`
    };

    const statusKey = notification?.notification_for;
    let status = '';

    if (statusMap.hasOwnProperty(statusKey)) {
      status = `${this.currentURL}${notification?.redirect_url}${notification?.notification_for_id}${statusMap[statusKey]}`;
    } else {
      // Handle the default case or unknown notification types
    }

    return status;
  };

  readMessage(id: string) {
    this._socket.readMessage(this.utility.base64Encode(id)).pipe(distinctUntilChanged(), takeUntil(this._unsuscribe$)).subscribe({
      next: (res: any) => {
        console.log('res: ', res);
        this.actionNotificationCount = res?.data?.notificationCount

        // console.log('res: ', res);
      },
      error: (error: any) => {
        console.log('error: ', error);
      }
    })
  }
  ngOnDestroy(): void {
    // this._socket.notifications.unsubscribe()
    // this._socket.unsubscribe()
    
    this._unsuscribe$.next(true)
    this._unsuscribe$.complete()

  };
}


