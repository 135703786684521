import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  togglePassword: boolean = true;
  isFormSubmitted: boolean = false;
  loginForm: FormGroup;
  private _unsubscribe$ = new Subject<boolean>();

  constructor(
    private _utility : UtilityService,
    private _router : Router
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        this._utility.validateEmail
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });
   }

  ngOnInit(): void {
  }

  login(){
    this.isFormSubmitted = true;
    this._router.navigate(['/individual-developer']);
  }

}
