import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-project-layout',
  templateUrl: './project-layout.component.html',
  styleUrls: ['./project-layout.component.scss']
})
export class ProjectLayoutComponent  implements OnInit{
 
  showLoading!: Observable<boolean>;
  showHeaderView: boolean = true;

  dashboardBackground: boolean = false;

  ngOnInit(): void {
    this.showHeaderView = window.location.pathname.includes('generate-resume') ? false : window.location.pathname.includes('generate-ai-resume') ? false : true;
    this.dashboardBackground = window.location.pathname.includes('/dashboard/') ? true: false

  }

}

