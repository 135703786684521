import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, fromEvent, takeUntil } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility.service';
import { autoLogin, loginStart } from 'src/app/core/store/auth-state/auth.actions';
import { isAuthenticated } from 'src/app/core/store/auth-state/auth.selector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit,OnDestroy {
  isAuthenticated!: Observable<boolean>;
  togglePassword: boolean = true;
  isFormSubmitted: boolean = false;
  loginForm: FormGroup;
  errorMessage! : Observable<string>;
  showErrorMessage : boolean = false;
  private unsubscriber : Subject<void> = new Subject<void>();

  constructor(
    private _utility : UtilityService,
    private store : Store<any>,
    private router : Router,
  ) {
    this.preventBackNavigation();

    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        this._utility.validateEmail
      ]),
      password: new FormControl('', [
        Validators.required
      ]),
      admin: new FormControl('admin', [
        Validators.required
      ])
    });
   }

   preventBackNavigation() {
    history.pushState(null, '');

  fromEvent(window, 'popstate').pipe(
    takeUntil(this.unsubscriber)
  ).subscribe((_) => {
    history.pushState(null, '');
  });
  }
  ngOnInit(): void {
    this.isAuthenticated = this.store.select(isAuthenticated);
    if(this.isAuthenticated) {
      this.store.dispatch(autoLogin());
    } else {
      this.router.navigate(['/auth/login'])
    }
  }
  
  //Login btn action 
  login(){
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    this.store.dispatch(loginStart({
      email, password
    }));
  }
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  
}
