export class User  {
    constructor(
        private first_name : string,
        private last_name : string,
        private email : string,
        public token : any,
        public role : [{
            _id : string;
            role_name : string;
        }],
        private profile_pic : string,
        private user_id : string,
        public permissions? : any

    ) {}


public get userToken() : any {
    return this.token
}
}