import { MenuItem } from "primeng/api";
import { SideNavMenu } from "./core/services/models/side-nav.model";
const iconsUrl = "../../../../assets/images/sidenav";



export const MENU_ITEMS = [
  {
    label: 'Dashboard',
    value: '',
    alt: 'Dashboard',
    // icon: iconsUrl + "/dashboard-icon.svg",
    icon: "dashboard-icon",
    // routerLink: '/dashboard',
    queryParams: {
      groupBy: 'weekly',
    },
    items: [
      {
        label: 'Overall',
        value: '',
        alt: 'Overall',
        routerLink: '/dashboard/overall',
        icon: "inner-child",
        queryParams: {
        month: 12,
        year: 2024,
        filteredBy: 'monthly',
        tabRedirect: 'source',
        compareBy: 'monthFilter',
        yearTo: 2024,
        limit: 10,
        offset: 0
    },
      },

      {
        label: 'Lead',
        value: '',
        alt: 'Lead',
        routerLink: '/dashboard/lead-dashboard',
        icon: "inner-child",
      },
      {
        label: 'POC',
        value: '',
        alt: 'POC',
        routerLink: '/dashboard/poc-dashboard',
        icon: "inner-child",
      },
      {
        label: 'Job',
        value: '',
        alt: 'Job',
        routerLink: '/dashboard/job-dashboard',
        icon: "inner-child",
      },

      // {
      //   label: 'Client',
      //   value: '',
      //   alt: 'Client',
      //   routerLink: '/dashboard/client-dashboard',
      //   icon: "inner-child",
      // },
      // {
      //   label: 'Candidate',
      //   value: '',
      //   alt: 'Candidate',
      //   routerLink: '/dashboard/candidate-dashboard',
      //   icon: "inner-child",
      // },
      // {
      //   label: 'Vendor',
      //   value: '',
      //   alt: 'Vendor',
      //   routerLink: '/dashboard/vendor-dashboard',
      //   icon: "inner-child",
      // },
      // {
      //   label: 'Freelance Recruiters',
      //   value: '',
      //   alt: 'Freelance Recruiters',
      //   routerLink: '/dashboard/freelance-recruiters-dashboard',
      //   icon: "inner-child",
      // },
    ],
  },
  {
    label: 'TMT Dashboard',
    value: '',
    alt: 'TMT Dashboard',
    icon: 'TMT-Dashboard-icon',
    routerLink: '/tmt/tmt-dashboard',
  },
  {
    label: 'TMT Delegation',
    value: '',
    alt: 'TMT Delegation',
    icon: 'TMT-Dashboard-icon',
    routerLink: '/tmt-delegation',
  },
  {
    label: 'TMT Delegation',
    value: '',
    alt: 'Associate TMT Delegation',
    icon: 'TMT-Dashboard-icon',
    routerLink: '/associate-delegation',
  },
  {
    label: 'Hired Developers',
    value: '',
    alt: 'Hired Developers',
    icon: 'developers-icon',
    routerLink: '/new-hired-developers/',
  },
  {
    label: 'Developers',
    value: '',
    alt: 'Developers',
    icon: 'hired-dev-icon',
    routerLink: '/individual-developer/',
    // queryParams: {
    //   offset: 0,
    //   limit: 10,
    // },
  },
  {
    label: 'Job Management',
    value: '',
    alt: 'Job Management',
    icon: 'job-manag-icon',
    routerLink: '/job-management/',
    // queryParams: {
    //   offset: 0,
    //   limit: 10,
    //   viewMode : 'OpenJob'
    // },
  },
  {
    label: 'Leads',
    value: '',
    alt: 'lead-icon',
    icon: 'lead-icon',
    routerLink: '/clients/',
    // queryParams: {
    //   offset: 0,
    //   limit: 10,
    //   type: 'all',
    // },
  },

  {
    label: 'Vendors',
    value: '',
    alt: 'vendor-icon',
    icon: 'vendor-icon',
    routerLink: '/vendors/',
    // queryParams: {
    //   offset: 0,
    //   limit: 10,
    // },
  },
  // {
  //   label: 'Freelance Recruiter',
  //   value: '',
  //   alt: 'freelance',
  //   icon: 'freelance-icon',
  //   routerLink: '/freelance',
  //   // queryParams: {
  //   //   tab: 'onboarded',
  //   // },
  // },
  {
    label: 'AI Resume',
    value: '',
    alt: 'AI Resume',
    icon: 'ai-resume-icon',
    routerLink: '/resume-builder',
  },
  {
    label: 'SS Team',
    value: '',
    alt: 'SS Team',
    icon: 'ss-team-icon',
    routerLink: '/ss-team',
  },
];





function formatDate(date = new Date()) {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', { day: '2-digit' });
  return [year, month, day].join('-');
}

function before24HoursDate(date = new Date()) {
  let timeDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  const year = timeDate.toLocaleString('default', { year: 'numeric' });
  const month = timeDate.toLocaleString('default', {
    month: '2-digit',
  });
  const day = timeDate.toLocaleString('default', { day: '2-digit' });
  return [year, month, day].join('-');
}
