import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface AlertMessagesInterface {
  heading: String
  description: String
  firstBtn: String
  secondBtn: String
}

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss']
})
export class AlertMessagesComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AlertMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public getDetails: AlertMessagesInterface,
  ) { }

  ngOnInit(): void {
    console.log(this.getDetails);

  }
  onSubmit() {
    this.dialogRef.close({ data: "Success" });
  }
  cancel() {
    this.dialogRef.close();
  }
}
