import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';

import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '../core/store/auth-state/auth.effects';
import { HttpClientModule } from '@angular/common/http';
import { CreatePasswordComponent } from './pages/create-password/create-password.component';


@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    CreatePasswordComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    HttpClientModule,
    EffectsModule.forFeature(),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AuthModule { }
