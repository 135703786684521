import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectFilterModule } from 'mat-select-filter';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatExpansionModule } from '@angular/material/expansion';
import {DialogModule} from 'primeng/dialog';

// PrimeNg
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableModule} from 'primeng/table';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import {TooltipModule} from 'primeng/tooltip';
import {SidebarModule} from 'primeng/sidebar';
import {AccordionModule} from 'primeng/accordion';
import {DividerModule} from 'primeng/divider';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {PaginatorModule} from 'primeng/paginator';
import {AutoCompleteModule} from 'primeng/autocomplete';


// import { PrimeIcons } from 'primeng/api';
// import { PrimeIcons} from 'primeng/api';
import { MatGridListModule } from '@angular/material/grid-list';

const MATERIAL_MODULES: any = [
  MatCardModule,
  MatInputModule,
  MatTableModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatPaginatorModule,
  MatMenuModule,
  MatDialogModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatSelectModule,
  MatDatepickerModule,
  MatSidenavModule,
  MatListModule,
  MatRadioModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatStepperModule,
  MatBadgeModule,
  DragDropModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatSelectFilterModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatSortModule,
  NgSelectModule,
  MatExpansionModule,

  // PrimeNg
  DialogModule,
  CalendarModule,ButtonModule,InputTextModule,CheckboxModule,MultiSelectModule,ProgressSpinnerModule,
  PanelMenuModule,DropdownModule,ChipModule,TooltipModule, CalendarModule,TableModule,
  SidebarModule,AccordionModule,DividerModule,AvatarModule,AvatarGroupModule,PaginatorModule,
  AutoCompleteModule,

  MatGridListModule
]
@NgModule({
  declarations: [],
  imports: MATERIAL_MODULES,
  exports: MATERIAL_MODULES
})
export class MaterialModule { }
