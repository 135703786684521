import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { monthList } from '../utilities/utils';

@Injectable({ providedIn: 'root' })
export class UtilityService {
  constructor(
    private router: Router,
    private viewPortScroll: ViewportScroller

  ) { }
  month: any = [
    { value: '0', name: 'Jan' },
    { value: '1', name: 'Feb' },
    { value: '2', name: 'Mar' },
    { value: '3', name: 'Apr' },
    { value: '4', name: 'May' },
    { value: '5', name: 'Jun' },
    { value: '6', name: 'Jul' },
    { value: '7', name: 'Aug' },
    { value: '8', name: 'Sep' },
    { value: '9', name: 'Oct' },
    { value: '10', name: 'Nov' },
    { value: '11', name: 'Dec' },
  ];

  year: any = [
    { value: '0', name: 2023 },
    { value: '1', name: 2024 },
    // { value: '2', name: 2023 },
    // { value: '2', name: 2024 },
  ];

  day: any = [
    { value: 0, name: 'Sun' },
    { value: 1, name: 'Mon' },
    { value: 2, name: 'Tue' },
    { value: 3, name: 'Wed' },
    { value: 4, name: 'Thu' },
    { value: 5, name: 'Fri' },
    { value: 6, name: 'Sat' }
  ]
  private teamHeaderCount = new BehaviorSubject<any>([]);
  private logedInUserDetails = new BehaviorSubject<any>([]);

  public scrollToElement$ = new BehaviorSubject<boolean>(false);
  public clearSearch$ = new BehaviorSubject<boolean>(false);
  private isIDPSubject$ = new BehaviorSubject<boolean>(false);

  teamCount = this.teamHeaderCount.asObservable();
  userDetails = this.logedInUserDetails.asObservable();
  isIDPObservable: Observable<boolean> = this.isIDPSubject$.asObservable();
  updatedUserDetails(userDetails: any) {
    this.logedInUserDetails.next(userDetails);
  }

  updateTeamHeaderCount(updatedCount: any) {
    this.teamHeaderCount.next(updatedCount);
  }

  removeFirstWhiteSpace() {
    return (control: any) => {
      if (control.value) {
        const trimmedValue = control.value.replace(/^\s+/g, '');
        if (trimmedValue !== control.value) {
          control.patchValue(trimmedValue);
        }
      }
      return null;
    };
  }

  //url and linkedin Rejex
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  linkedInRegex: any =
    /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

  //validate email
  validateEmail(emailControl: any) {
    const regExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!regExp.test(emailControl.value)) {
      return { validateEmail: true };
    }
    return null;
  }

  validateEmailInput(value: any) {
    const regExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!regExp.test(value)) {
      return { validateEmail: true };
    }
    return null;
  }

  validatePassword(passControl: FormControl) {
    const passRegExp = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{6,}/);
    if (passRegExp.test(passControl.value)) {
      return null;
    } else {
      return { validatePassword: true };
    }
  }

  passwordMatch(password: string, confirmPassword: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const passwordControl = formGroup.get(password);
      const confirmPasswordControl = formGroup.get(confirmPassword);

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordMismatch']) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        confirmPasswordControl.setErrors(null);
        return null;
      }
    };
  }

  // Encode id
  base64Encode(stringText: string) {
    return window.btoa(stringText);
  }
  // Decode id
  base64Decode(stringText: string) {
    return window.atob(stringText);
  }

  darkenToLightenColor(hexColor: any, percent: any) {
    const validHexColor = /^#([0-9a-f]{3}){1,2}$/i.test(hexColor);
    if (!validHexColor) {
      console.error('Invalid hex color code');
      return null;
    }
    hexColor = hexColor.slice(1);
    let r = parseInt(hexColor.slice(0, 2), 16);
    let g = parseInt(hexColor.slice(2, 4), 16);
    let b = parseInt(hexColor.slice(4, 6), 16);
    r = Math.round(r + ((255 - r) * percent) / 100);
    g = Math.round(g + ((255 - g) * percent) / 100);
    b = Math.round(b + ((255 - b) * percent) / 100);

    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    const lightenedHexColor = `#${(r * 0x10000 + g * 0x100 + b).toString(16).padStart(6, '0')}`;

    return lightenedHexColor;
  }

  // Example usage:

  //Scroll animation
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
  // scroll to the error
  scrollToError(): void {
    const firstElementWithError: any = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  // SS individual average ratting
  calculateAverage(...args: Array<number>): any {
    return (args.reduce((partialSum, current) => partialSum + current, 0) / args.length)?.toFixed(1);
  }

  scrollToElement(id: string): void {
    this.router.navigate([], {
      fragment: id,
    });
    this.viewPortScroll.scrollToAnchor(id);
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    let keyArray = [107, 109, 189, 187, 38, 40, 190, 110, 69];
    if (keyArray.includes(charCode)) {
      return false;
    }
    return true;
  }

  // How may days between two dates
  daysCount(date: any) {
    const today = new Date().toISOString().slice(0, 10);
    const msInDay = 24 * 60 * 60 * 1000;
    const daysBefore = Math.round(Math.abs(parseInt(date) - parseInt(today)) / msInDay);
    if (daysBefore == 0) {
      return 'today';
    } else if (daysBefore == 1) {
      return daysBefore + ' day ago';
    } else {
      return daysBefore + ' days ago';
    }
  }

  daysGap(date: any) {
    const today = new Date().toISOString().slice(0, 10);
    const msInDay = 24 * 60 * 60 * 1000;
    const daysBefore = Math.round(Math.abs(parseInt(date) - parseInt(today)) / msInDay);
    return daysBefore;
  }

  //Calculate Time to fill
  timeToFill(date: string, closedDate?: string) {
    // let sachin = Math.floor(Math.random() * 7) + 1;
    const jobPostedDate = new Date(date);
    let today;
    if (closedDate) {
      today = new Date(closedDate);
    } else {
      today = new Date();
    }
    const msInDay = 24 * 60 * 60 * 1000;
    var Difference_In_Time = today.getTime() - jobPostedDate.getTime();
    var Difference_In_Days = Difference_In_Time / msInDay;
    return Math.round(Difference_In_Days) + ' days';
  }
  setErrorNUll(controlKeysToIgnore: any, formGroup: any) {
    for (const controlName in formGroup) {
      if (Object.prototype.hasOwnProperty.call(formGroup, controlName)) {
        if (!controlKeysToIgnore.includes(controlName)) {
          const control = formGroup[controlName];
          control.updateValueAndValidity();
          control.setErrors(null);
        }
      }
    }
  }

  stringToText(stringText: any): string {
    return window.atob(stringText);
  }

  // remove null keys from form
  // removedNullKeys(form: any) {
  //   // const filtered: any = {};
  //   // for (let key in form.value) {
  //   //   if (form.value[key] || form.value[key] == '') {
  //   //     filtered[key] = form.value[key];
  //   //   }
  //   // }
  //   // return filtered

  // }
  removedNullKeys = (obj: any) => {
    Object.keys(obj).forEach((key: any) => {
      if (key != 'primarySkills') {
        (obj[key] && typeof obj[key] === 'object' && this.removedNullKeys(obj[key])) ||
          ((obj[key] === undefined || obj[key] === null) && delete obj[key]);
      }
    });
    return obj;
  };
  // removeNullSkills
  removeNullSkills(skillsArray: any) {
    // if(skillsArray){
    const filteredArray = skillsArray?.filter((item: any) => item.skill_name !== undefined && item.skill_name !== null);
    return filteredArray;
    // }
  }

  // removeNullSkills
  removeNullJobSkills(skillsArray: any) {
    // if(skillsArray){
    const filteredArray = skillsArray?.filter((item: any) => item.skill !== undefined && item.skill !== null);
    return filteredArray;
    // }
  }

  // removeNullorEmptyInterview
  removeNullorEmptyInterview(skillsArray: any) {
    // if(skillsArray){
    const filteredArray = skillsArray?.filter(
      (item: any) => item.round_name !== undefined && item.round_name !== null && item.round_name !== ''
    );
    return filteredArray;
    // }
  }

  linkedInPrefix(link: string) {
    const regex = /https:\/\//;
    const str = link;
    if (regex.test(str)) {
      return link;
    } else {
      return 'https://www.' + link;
    }
  }

  calcPercentage(digit1: number, digit2: number) {
    return Math.round((digit1 / digit2) * 100);
  }

  minutesToHours(num: any) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    return hours + ' h ' + Math.round(minutes) + ' mins';
  }

  returnToday(date: any) {
    var today = new Date().getDate();
    var taskDate = new Date(date).getDate();
    if (today == taskDate) {
      return 'Today';
    } else if (today - taskDate == 1) {
      return 'Yesterday';
    } else {
      var datePipe = new DatePipe('en-US');
      return datePipe.transform(date, 'EEE, dd MMM yyyy');
    }
  }


  getMonthName(startMonth: number, endMonth: number): any[] {
    const months = monthList;
    const monthNames = [];
    for (let i = startMonth - 1; i < endMonth; i++) {
      if (i >= 0 && i <= 11) {
        monthNames.push(months[i]);
      }
    }
    return monthNames;
  }

  selectedMonthName(monthNumber: number): any {
    const months = monthList;
    const monthName = [];
    monthName.push(months[monthNumber]);
    return monthName;
  }

  //Calculate Time to fill
  noticePeriod(closedDate: string) {
    // let sachin = Math.floor(Math.random() * 7) + 1;
    let today;
    today = new Date();
    const jobPostedDate = new Date(closedDate);
    const msInDay = 24 * 60 * 60 * 1000;
    var Difference_In_Time = today.getTime() - jobPostedDate.getTime();
    var Difference_In_Days = Difference_In_Time / msInDay;
    return Math.round(Difference_In_Days);
  }

  calculateDaysBetweenDates(date1: Date, date2: Date): number {
    const diffInMs: number = Date.parse(date2.toString()) - Date.parse(date1.toString());
    const diffInDays: number = diffInMs / (1000 * 60 * 60 * 24);
    return Math.round(diffInDays);
  }

  /**
   * Function to delete null/empty keys from an object
   * @param data type `Object`
   * @returns `Object`
   */
  deleteNullKeysInObject(data: any) {
    Object.keys(data).map(function (key) {
      if (data[key] == null || data[key] == '' || data[key] == undefined) {
        delete data[key];
      }
    });
    return data;
  }

  getFirstLetters(str: any) {
    let firstLetters;
    if (str != null) {
      firstLetters = str
        .split(' ')
        .map((word: any) => word.charAt(0))
        .join('');
    }
    if (firstLetters?.length > 2) {
      return firstLetters.substring(0, 2);
    }
    return firstLetters;
  }

  calculateSum(array: any, property: any) {
    const total = array.reduce((accumulator: any, object: any) => {
      return accumulator + object[property];
    }, 0);

    return total;
  }

  getSingleElementFromArray(array: any, key: string, value: string) {
    const found = array.find((user: any) => user[key] === value);
    return found;
  }

  /**
   * Function to convert bytes to readable string
   * @param value Bytes
   * @returns String
   */
  convertBytes(value: any): string {
    if (value / 1000 < 1000) {
      return Math.floor(+value / 1000) + ' KB';
    } else {
      return Math.floor(+value / 1000000) + ' MB';
    }
  }

  alphabetOnly(event: any) {
    var regex = new RegExp('^[a-zA-Z ._\b\t]+$');
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  addCommas(event: any, formControl: AbstractControl): void {
    let value = event.target ? event.target.value.toString().replace(/,/g, '') : event.toString().replace(/,/g, '');
    if (!+value) {
      return;
    }
    formControl.setValue((+value).toLocaleString('en-IN'));
  }

  /**
   * Remove spaces from end of form control's value
   * @param {AbstractControl} control Form control whose value must be trimmed
   */
  removeSpacesFromInput(control: AbstractControl): void {
    if (control.value?.length) control.setValue(control.value.trim());
  }

  /**
   * Function removed if zero after decimal
   */
  removedZeroAfterDecimal(value: any): void {
    if (value % 1 === 0) {
      let integerNumber: any = Math.floor(value);
      return integerNumber;
    } else {
      return value;
    }
  }

  // return total of array of number
  sumOfArray(arrayOfNum: any) {
    if (arrayOfNum == null || arrayOfNum.length == 0) {
      return 0;
    }
    let total = arrayOfNum?.reduce((a: number, b: number) => a + b);
    return total;
  }

  returnObjFromArray(array: any, actionType: string) {
    var result = array.filter((obj: any) => {
      return obj.action_type === actionType;
    });
    if (result.length) {
      return result[0].created_at;
    } else {
      return '-';
    }
  }

  mergeArrayByKey(mainArray: any, secondaryArray: any, keyToPush: any) {
    const idMap = new Map();
    // Create a map of IDs and values from the secondary array
    for (const item of secondaryArray) {
      idMap.set(item.id, item[keyToPush]);
    }
    // Merge the key into the main array based on the ID
    const mergedArray = mainArray?.map((item: any) => {
      const key = idMap.get(item.engineer_id);
      if (key !== undefined) {
        return { ...item, [keyToPush]: key };
      }
      return item;
    });
    return mergedArray;
  }

  matchingArrayByKey(mainArray?: any, secondaryArray?: any, keyToPush?: any) {
    const idMap = new Map();

    const percentageRegex = /Overall Matching Percentage:\s*([0-9]+)\s*%/;
    // Create a map of IDs and values from the secondary array
    for (const item of secondaryArray) {
      idMap.set(item.userId, { justification: item?.justification, is_click: item?.is_click });

      // idMap.set(item.userId, item?.justification,item?.is_click);
    }

    // Merge the key into the main array based on the ID
    const mergedArray = mainArray?.map((item: any) => {
      const key = idMap.get(item.engineer_id);

      if (key !== undefined) {
        return { ...item, [keyToPush]: key };
      }
      return item;
    });
    //

    return mergedArray;
  }

  /**
   * Function to open linkedin url in new tab
   * @param url Linkedin profile URL
   */
  openUrlInNewTab(url: string): void {
    if (!url.includes('https') && !url.includes('http')) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }

  /**
   * Function to open linkedin url in new tab
   * @param url Linkedin profile URL
   */
  convertFrom24To12Format = (time24: any) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? 'AM' : 'PM';
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  };

  // mili seconds to hours convert
  msToTime(ms: number) {
    if (ms == null) {
      return;
    }
    if (ms == 0) {
      return 0;
    }
    let seconds: any = (ms / 1000)?.toFixed(1);
    let minutes: any = (ms / (1000 * 60))?.toFixed(1);
    let hours: any = (ms / (1000 * 60 * 60))?.toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24))?.toFixed(1);
    if (seconds < 60) return seconds + ' Sec';
    else if (minutes < 60) return minutes + ' Min';
    else if (hours < 24) return hours + ' Hrs';
    else return days + ' Days';
  }
  // msToMonthandDays(ms: number) {
  //   if (ms == null || ms === 0) {
  //     return '0d 0h';
  //   }

  //   let seconds: any = Math.floor((ms / 1000) % 60);
  //   let minutes: any = Math.floor((ms / (1000 * 60)) % 60);
  //   let hours: any = Math.floor((ms / (1000 * 60 * 60)) % 24) || 0;
  //   let days: any = Math.floor(ms / (1000 * 60 * 60 * 24)) || 0;

  //   let timeString = '';

  //   if (hours || days) {
  //     timeString += days + "d " + hours + "h";

  //   }
  //   if (timeString.trim() === '') {
  //     timeString = '0d 0h';
  //   }
  //   return timeString.trim();
  // }
  msToMonthandDays(ms: number) {
    if (ms == null || ms === 0) {
      return "0d 0h";
    }

    let seconds: any = Math.floor((ms / 1000) % 60);
    let minutes: any = Math.floor((ms / (1000 * 60)) % 60);
    let hours: any = Math.floor((ms / (1000 * 60 * 60)) % 24) || 0;
    let days: any = Math.floor(ms / (1000 * 60 * 60 * 24)) || 0;

    let timeString = '';
    if (days) {
      timeString += days + "d ";
    }
    if (hours) {
      timeString += hours + "h";

    }
    if (timeString.trim() === '') {
      timeString = "0d 0h";
    }

    return timeString.trim();
  }


  secToTime(ms: number) {
    if (ms == 0) {
      return 0;
    }
    let seconds: any = ms?.toFixed(1);
    let minutes: any = (ms / 60)?.toFixed(1);
    let hours: any = (ms / (60 * 60))?.toFixed(1);
    let days = (ms / (60 * 60 * 24))?.toFixed(1);
    if (seconds < 60) return seconds + ' Sec';
    else if (minutes < 60) return minutes + ' Min';
    else if (hours < 24) return hours + ' Hrs';
    else return days + ' Days';
  }

  hrsToTime(ms: number) {
    if (ms == 0 || ms == null) {
      return 0;
    }
    let hours: any = ms;
    let days = (ms / 24)?.toFixed(1);
    if (hours < 24) return hours + ' Hrs';
    else return days + ' Days';
  }

  // return true false as per upcomming or past date
  dateStatus(timeStamp: string, currentDate: any) {
    let date = new Date(timeStamp);
    let activeDate = currentDate;
    return date > activeDate;
  }

  formatURL(url: string): string {
    // Check if the URL already starts with "http://" or "https://"
    if (!/^https?:\/\//i.test(url)) {
      // Add "https://" to the URL
      url = `https://${url}`;
    }

    // Check if the URL already starts with "www."
    if (!/^www\./i.test(url)) {
      // Add "www." to the URL
      url = url.replace(/^https?:\/\//i, 'https://www.');
    }

    return url;
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  returnQueryParams(queryParams: any): string {
    let query = '';
    if (queryParams) {
      var esc = encodeURIComponent;
      query += Object.keys(queryParams)
        .map((k) => esc(k) + '=' + esc(queryParams[k]))
        .join('&');
    }
    return query != '' ? '?' + query : '';
  }

  secondsToHms(time: any) {
    time = Number(time);
    var h = Math.floor(time / 3600);
    var m = Math.floor((time % 3600) / 60);
    var s = Math.floor((time % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
  }
  // getDifferenceByDate takes updatedDate and Created Date and return difference in minutes  and hours and days
  getDifferenceByDate(updatedDate: any, createdDate: any): string {
    const updatedAt: Date = new Date(updatedDate);
    const createdAt: Date = new Date(createdDate);
    updatedAt.setSeconds(0, 0);
    createdAt.setSeconds(0, 0);

    const differenceInMilliseconds: number = createdAt.getTime() - updatedAt.getTime();

    const differenceInDays: number = Math.floor(differenceInMilliseconds / (1000 * 3600 * 24));
    let remainingHours: any;

    if (differenceInDays < 1) {
      const differenceInHours: number = Math.floor(differenceInMilliseconds / (1000 * 3600));
      const remainingHours: number = differenceInHours % 24;

      return `${Math.abs(differenceInDays)}d ${Math.abs(remainingHours)}h`;
    } else {
      const absoluteDifference = Math.abs(differenceInDays);
      const hoursDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

      remainingHours = hoursDifference % 24;

      return `${absoluteDifference} ${absoluteDifference === 1 ? 'd' : 'd'} ${remainingHours}h`;
    }
  }

  getJobPostedTimeAgo(jobPostedDate: any) {
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate.getTime() - new Date(jobPostedDate).getTime());
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const remainingHours = hoursDifference % 24;
    const remainingMinutes = minutesDifference % 60;

    if (minutesDifference < 60) {
      // Within the last hour
      return `0d 0h`;
    } else if (hoursDifference < 24) {
      // Within the last 24 hours
      return `0d ${hoursDifference}h`;
    } else if (daysDifference < 1) {
      // Less than 1 day ago
      return `0d ${remainingHours}h`;
    } else if (daysDifference < 2) {
      // Within the last two days
      return `${daysDifference}d ${remainingHours}h`;
    } else if (daysDifference < 7) {
      // Within the last week
      return `${daysDifference}d ${remainingHours}h`;

      // return `${daysDifference} d` ;
    } else {
      // More than one week ago
      return `${daysDifference}d ${remainingHours}h`;

      // return `${daysDifference} d`;
    }
  }

  // Update days diffrance
  getJobPostedTimeAgoLetestUpdate(jobPostedDate: Date): string {
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - new Date(jobPostedDate).getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    if (minutesDifference < 60 && minutesDifference !== -1) {
      return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else if (daysDifference < 1) {
      return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
    } else if (daysDifference < 7) {
      return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
    } else if (daysDifference < 14) {
      return '1 week ago';
    } else {
      // const weeksDifference = Math.floor(daysDifference / 7);
      // return `${weeksDifference} ${weeksDifference === 1 ? 'week' : 'weeks'} ago`;
      const weeksDifference = Math.floor(daysDifference / 7);
      const remainingDays = daysDifference % 7;
      return `${weeksDifference} ${weeksDifference === 1 ? 'week' : 'weeks'} and ${remainingDays} ${remainingDays === 1 ? 'day' : 'days'} ago`;
    }
  }

  getJobPostedTimeAgoLatestUpdate(jobPostedDate: Date): string {
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - new Date(jobPostedDate).getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const hoursRemainder = hoursDifference % 24;

    if (minutesDifference < 60) {
      return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else if (hoursDifference < 24) {
      return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
    } else if (daysDifference < 7) {
      return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} and ${hoursRemainder} ${hoursRemainder === 1 ? 'hour' : 'hours'} ago`;
    } else if (daysDifference < 14) {
      return '1 week ago';
    } else {
      const weeksDifference = Math.floor(daysDifference / 7);
      const remainingDays = daysDifference % 7;
      return `${weeksDifference} ${weeksDifference === 1 ? 'week' : 'weeks'} and ${remainingDays} ${remainingDays === 1 ? 'day' : 'days'} and ${hoursRemainder} ${hoursRemainder === 1 ? 'h' : 'h'} ago`;
    }
  }

  getJobPostedLastActionon(jobPostedDate: any) {
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate.getTime() - new Date(jobPostedDate).getTime());
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const remainingHours = hoursDifference % 24;
    const remainingMinutes = minutesDifference % 60;

    if (minutesDifference < 60) {
      // Within the last hour
      return `0d 0h`;
    } else if (hoursDifference < 24) {
      // Within the last 24 hours
      return `0d ${hoursDifference} ${hoursDifference === 1 ? 'h' : 'h'}`;
    } else if (daysDifference < 1) {
      // Less than 1 day ago
      return `0 d ${remainingHours} ${remainingHours === 1 ? 'h' : 'h'}`;
    } else if (daysDifference < 2) {
      // Within the last two days
      return `${daysDifference}d ${remainingHours}${remainingHours === 1 ? 'h' : 'h'}`;
    } else if (daysDifference < 7) {
      // Within the last week
      return `${daysDifference}d ${remainingHours}${remainingHours === 1 ? 'h' : 'h'}`;
    } else {
      // More than one week ago
      return `${daysDifference}d ${remainingHours}${remainingHours === 1 ? 'h' : 'h'}`;
    }
  }

  /**
   * Function to set queryParams in URL with merge as queryParams handling
   * @param data Data with key value pairs to be set as queryParams in URL
   */
  addQueryParamsToUrl(data: any): void {
    this.router.navigate([], {
      queryParams: data,
      queryParamsHandling: 'merge',
    });
  }
  // updateIDPStatus(isIDP: boolean) {
  //   this.isIDPSubject$.next(isIDP);
  // }

  /**
   * @description The function disables the space key.
   */
  disableSpace(event: any) {
    if ((event.charCode && event.charCode === 32) || (event.which && event.which === 32)) {
      event.preventDefault();
    }
  }

  capitalizeFirstLetterAfterSpace(str: string): string {
    if (!str) return '-';
    return str.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  }

  /**
   * @param data type Onject
   * @returns {Object} `Object`
   */
  deleteUndifindKeysInObject(data: any) {
    Object.keys(data).map(function (key) {
      if (data[key] == null || data[key] == 'null' || data[key] == undefined || (typeof data[key] == 'string' && data[key] == '')) {
        delete data[key];
      };
    });
    return data;
  };

  /**
   * @param url redirect to url and target absolute url
   */
  redirectTo(url: string, queryParams?: any): void {
    this.router.navigateByUrl('/redirect', { skipLocationChange: true }).then(() => {
      this.router.navigate([url], {
        queryParams: queryParams
      });
    })
  };
  updatePillsArrayCounts(res: any, array: any) {
    if (res != undefined) {
      array.forEach((element: any) => {
        if (element.key && res.hasOwnProperty(element.key)) {
          element.count = res[element.key];
        }
      });
    }
    return array;
  }
}
