<section class="login-signup" fxLayout="row" fxLayoutAlign="stretch">
    <div class="left-bar" fxHide.sm fxHide.xs>
           
    </div>

    <!-- Login Form  Start-->
    <mat-card class="individual-login-card right-bar mat-in-login">
        <div class="signup-form-box">
             <div fxLayout="row">
                <div fxLayout="column" class="head-desc">
                    <div class="logo-img"> <img src="../../../../assets/images/supersourcing-logo.svg" alt="Supersourcing"/></div>
                    <div class="text-xlg-grey ptm">Please enter the email address you'd like your password reset information send to</div>
                </div>
            </div>
            <form (ngSubmit)= "this.loginForm.valid && login()" [formGroup]= "this.loginForm">
                <div fxLayout="column">
                    <mat-label>Enter email</mat-label>
                    <mat-form-field class="input-full-width" appearance="outline">
                        <input type="text" matInput placeholder="Enter email" formControlName="email">
                        <mat-error *ngIf= "loginForm.controls['email'].hasError('required')">Email is required</mat-error>
                        <mat-error
                            *ngIf= "loginForm.controls['email'].hasError('validateEmail') && !loginForm.controls['email'].hasError('required')">
                            Email is invalid</mat-error>
                    </mat-form-field>
                    
                </div>
                <div fxLayout="row" fxLayoutAlign="end end" class="mtm">
                    <button type="submit" class="btn-ribbon mat-signup" mat-button>Request reset link</button>
                </div>
                <div class="mat-forget mtl text-center">
                    <a routerLink="../login">Back to login</a>
                </div>
            </form>
        </div>
    </mat-card> 
    <!-- Login Form  End-->
</section>