<section class="login-signup" fxLayout="row" fxLayoutAlign="stretch">
    <div class="left-bar" fxHide.sm fxHide.xs>
           
    </div>

    <!-- Login Form  Start-->
    <mat-card class="individual-login-card right-bar mat-in-login">
        <div class="signup-form-box">
             <div fxLayout="row">
                <div fxLayout="column" class="head-desc">
                    <div class="logo-img"> <img src="../../../../assets/images/supersourcing-logo.svg" alt="Supersourcing"/></div>
                    <div class="text-xlg-grey ptm">Welcome to Supersourcing Administrator Panel</div>
                </div>
            </div>
            <form (ngSubmit)= "this.loginForm.valid && login()" [formGroup]= "this.loginForm">
                <div fxLayout="column">
                    <mat-label>Email</mat-label>
                    <mat-form-field class="input-full-width" appearance="outline">
                        <input type="text" matInput placeholder="Enter email" formControlName="email">
                        <mat-error *ngIf= "loginForm.controls['email'].hasError('required')">Email is required</mat-error>
                        <mat-error
                            *ngIf= "loginForm.controls['email'].hasError('validateEmail') && !loginForm.controls['email'].hasError('required')">
                            Email is invalid</mat-error>
                    </mat-form-field>
                    <mat-label>Password</mat-label>
                    <mat-form-field class="input-full-width" appearance="outline">
                        <input matInput [type]="togglePassword ? 'password' : 'text'" placeholder="******" formControlName="password">
                        <button type="button" mat-icon-button matSuffix (click)= "togglePassword = !togglePassword"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]= "togglePassword">
                            <mat-icon>{{togglePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error> Password is required</mat-error>
                    </mat-form-field>
                </div>
                <!-- <div fxLayoutAlign="end" class="mat-forget">
                    <a routerLink="../forgot-password">Forgot Password?</a>
                </div> -->
                <div fxLayout="row" fxLayoutAlign="end end" class="mtm">
                    <button type="submit" class="btn-ribbon mat-signup"  mat-button>Login</button>

                    <!-- <button type="submit" class="btn-ribbon mat-signup" *ngIf="!(isAuthenticated | async)" mat-button>Login</button> -->
                </div>
            </form>
        </div>
    </mat-card> 
    <!-- Login Form  End-->
</section>