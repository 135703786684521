<div fxLayoutAlign="center" fxLayout="column" class="dialog-popup">
  <div mat-dialog-content>
    <div>
      <h3 class="mt-0">{{ getDetails.heading }}</h3>
      <div class="mtl" fxLayout="row" fxLayoutGap="15px">
        <div fxFlex="100" style="text-align: center">
          <p class="fs-18 fw-500 mtn">{{ getDetails.description }}</p>
        </div>
      </div>
      <div>
        <div fxLayout="row" class="d-flax align-items-center mtl text-center" fxLayoutGap="15">
          <button type="button" (click)="cancel()" mat-raised-button class="btn-cancel">
            {{ getDetails.firstBtn }}
          </button>
          <button type="button" (click)="onSubmit()" mat-raised-button class="btn-invite ms-2">
            {{ getDetails.secondBtn }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
