import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from './core/store/app.state';
import { autoLogin } from './core/store/auth-state/auth.actions';
import { isAuthenticated } from './core/store/auth-state/auth.selector';
import { getErrorMessage, getLoader } from './core/store/shared-state/shared.selector';
import { WebservicesService } from './core/services/webservices.service';
import { AuthService } from './core/services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MessagingService } from './core/services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ss-admin';
  // showLoading: Observable<boolean>;
  showLoading!: Observable<boolean>;
  errorMessage!: Observable<string>;
  isAuthenticated!: Observable<boolean>;
  showHeaderView: boolean = true;
  constructor(
    private store: Store<AppState>,
    private _webSocketService: WebservicesService,
    private _auth: AuthService,
    private _router: Router,
    public _messaging: MessagingService,
    private _activatedRoute: ActivatedRoute,

  ) {
    let userCheck: any = localStorage.getItem('userData') && localStorage.getItem('isLatestNewTokenValid');
    if (userCheck) {
      this._messaging.requestPermission();
    }
  }
  loggedInUser: boolean = false;
  ngOnInit() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
      }
    });
    /**
     * Token expire and logout
     */
    // this.isLoggedInUser = window.location.pathname.includes('auth') ? false : true;
    let isTokenValid = this._auth.isTokenValid();

    let existingUser = this._auth.getUserFromLocalStorage()
    // console.log(existingUser, 'existingUser');
    // console.log(isTokenValid, 'isTokenValid');
    // if (isTokenValid) {
    //   this.loggedInUser = true;

    // }

    let checkForgetPass = window.location.pathname;
    if (checkForgetPass.includes('/auth/create-password')) {
      this._router.navigate([checkForgetPass]);
      return;
    } else {
      if (!isTokenValid && existingUser) {
        localStorage.removeItem("userData");
        this._router.navigate(['/auth/login'])
        this.loggedInUser = false;

      }
    }




    // this._router.events.subscribe((ev) => {
    //   if (ev instanceof NavigationEnd) {

    //     /* Your code goes here on every router change */
    //   }
    // });

    // this._webSocketService.connect();
    // this.showLoading = this.store.select(getLoading);
    this.errorMessage = this.store.select(getErrorMessage);
    // this.isAuthenticated = this.store.select(isAuthenticated);
    // if(this.isAuthenticated){
    this.store.dispatch(autoLogin());
    // }

  }
  checkForgetPass() {

  }





}
