import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TimeoutError } from 'rxjs';
import { AppState } from '../store/app.state';
import { autoLogout } from '../store/auth-state/auth.actions';
import { UtilityService } from '../utilities/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private _router: Router,
    private _utility: UtilityService,
    private toastr : ToastrService,
    private store : Store<AppState>
  ) { }

  /**
   * Function to open error dialog box and route to appropriate page
   * @param error {@link HttpErrorResponse} Error from backend
   */
  routeAccordingToError(error: any) {
    console.log("error", error);
    if (error.status === 0) {
      this.toastr.error('Please check your internet connection or try again later', 'Error');
    } 
    else if (error.status === 401) {
      // this.toastr.error(error.error.message, 'Error');
      this.store.dispatch(autoLogout());
    }
    else if(error.status === 403) {
      this.store.dispatch(autoLogout());
      this.toastr.error(error.error.message, 'Error');
    }
    else if (error.status === 422 && error.error.message == 'User not found') {
      this.toastr.error(error.error.message, 'Error');
      // this.store.dispatch(autoLogout());
    }
    else if (error.status === 422) {
      this.toastr.error(error.error.message, 'Error');
    } 
    else if (error.name === "TimeoutError") {
      // console.log("Timout", error);
      
      // this.toastr.error(error.message, 'Error');
      // this.store.dispatch(autoLogout());
    }
  }
}