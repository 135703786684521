<app-loader *ngIf="showLoading | async"></app-loader>
<div fxLayout="row" class="project-row-drama">
  <ng-container *ngIf="showHeaderView">
    <app-side-nav fxFlex="18%" class="side-nav"></app-side-nav>
  </ng-container>
  <div class="outlet">
    <div class="header-section" *ngIf="showHeaderView">
      <app-header></app-header>
    </div>
    <div class="outlet-section">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
