import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, filter, map, mergeMap, of, tap, timeout } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { ErrorHandlerService } from "../../services/error.handler.service";
import { AppState } from "../app.state";
import { setErrorMessage, setLoader } from "../shared-state/shared.actions";
import { autoLogin, autoLogout, loginStart, loginSuccess, loginSuccessAuto } from "./auth.actions";

@Injectable()
export class AuthEffects {
    timeOut: number = 20000;
    previousUrl: any;
    constructor(
        private action$: Actions,
        private authService: AuthService,
        private store: Store<AppState>,
        private router: Router,
        private route: ActivatedRoute,
        private errorHandler: ErrorHandlerService
    ) { }

    login$ = createEffect((): any => {
        return this.action$.pipe(
            ofType(loginStart)
            , exhaustMap((action) => {                
                this.store.dispatch(setLoader({ status: true }));
                return this.authService
                    .login(action.email, action.password)
                    .pipe(
                        timeout(this.timeOut),
                        map((data: any) => {
                            console.log('data: ', data);

                            this.store.dispatch(setLoader({ status: false }));
                            this.store.dispatch(setErrorMessage({ message: '' }));

                            const user = this.authService.formateUser(data);

                            this.authService.setUserInLocalStorage(user, true);
                            const role = user.role[0].role_name;

                            switch (role) {
                                case 'tp_associate':
                                    // this.router.navigate(['/job-management/list-assigned'], { queryParams: { offset: 0, limit: 10 } });
                                    this.router.navigate(['/job-management/list'], { queryParams: { offset: 0, limit: 10, viewMode : 'AssignedJob' } });

                                    break;

                                case 'cx_associate':
                                    this.router.navigate(['/individual-developer'], { queryParams: { offset: 0, limit: 10, status: 'preliminary' } });
                                    break;

                                case 'human_resources':
                                    this.router.navigate(['/hr-vetting/pending-list'], { queryParams: { offset: 0, limit: 10, status: 'preliminary' } });
                                    break;

                                case 'admin':
                                    this.router.navigate(['/individual-developer'], { queryParams: { offset: 0, limit: 10 } });
                                    break;

                                default:
                                    this.router.navigate(['/individual-developer'], { queryParams: { offset: 0, limit: 10 } });
                                    break;
                            }


                            return loginSuccess({ user, redirect: true });
                        }),
                        catchError((errResp: HttpErrorResponse) => {
                            this.store.dispatch(setLoader({ status: false }));
                            this.errorHandler.routeAccordingToError(errResp);
                            return of(setErrorMessage({ message: '' }));
                        })
                    );
            })
        );
    });

    loginRedirect$ = createEffect((): any => {       
        return this.action$.pipe(
            ofType(...[loginSuccessAuto]),
            tap((action) => {
                this.store.dispatch(setErrorMessage({ message: '' }));
                const user = this.authService.getUserFromLocalStorage();

                this.router.events
                    .pipe(filter(event => event instanceof NavigationEnd))
                    .subscribe((event: any) => {
                        this.previousUrl = event.url;
                    });
                if (action.redirect) {
                    this.store.dispatch(setLoader({ status: false }));

                    if ((this.previousUrl == '/auth/login' || this.previousUrl == '/') && user) {
                        if (user.role[0].role_name == "tp_associate" || user.role[0].role_name == "cx_associate") {
                            if (user.role[0].role_name == "tp_associate") {                               
                                // this.router.navigate(['/job-management/list-assigned'], { queryParams: { offset: 0, limit: 10 } });
                                this.router.navigate(['/job-management/list'], { queryParams: { offset: 0, limit: 10, viewMode : 'AssignedJob' } });

                            }
                            if (user.role[0].role_name == "cx_associate") {
                                this.router.navigate(['/individual-developer'], { queryParams: { offset: 0, limit: 10, status: 'preliminary' } });
                            }
                        }
                        if (user.role[0].role_name == "human_resources") {
                            this.router.navigate(['/hr-vetting/pending-list'], { queryParams: { offset: 0, limit: 10, status: 'preliminary' } });
                        }
                        else {
                            this.router.navigate(['/individual-developer'], { queryParams: { offset: 0, limit: 10 } });
                        }

                    }
                    else if (this.previousUrl == "/auth") {
                        this.router.navigate(['/individual-developer'], { queryParams: { offset: 0, limit: 10 } });
                        
                    }

                    else if (!this.previousUrl || this.previousUrl === '/auth' || this.previousUrl === '/') {
                        this.store.dispatch(setLoader({ status: false }));
                        
                        // this.router.navigate(['/auth/login']);
                        return;
                    }
                    else if (this.previousUrl !== undefined) {
                        this.store.dispatch(setLoader({ status: false }));

                        this.router.navigate([this.previousUrl]);
                        
                    }
                }
            })
        )
    },
        { dispatch: false }
    );

    autoLogin$ = createEffect((): any => {     
        return this.action$.pipe(
            ofType(autoLogin),
            mergeMap((action) => {              
                const user = this.authService.getUserFromLocalStorage();

                const completeUrl: any = this?.router?.url;

                const parts: any = completeUrl?.split('/');

                const lastString: any = parts[parts?.length - 1];

                if(user == null) {
                    this.authService.logout();
                    this.router.navigate(["/auth/login"]);
                }

                if (lastString == "") {
                }
                else if (lastString == 'login') {

                }
                else if (!user?.permissions) {

                    this.authService.logout();

                    this.router.navigate(["/auth/login"]);

                }
                return of(loginSuccessAuto({ user, redirect: true }));
            })
        );
    })

    logout$ = createEffect((): any => {
        return this.action$.pipe(ofType(autoLogout),
            map((action) => {
                this.authService.logout();
                this.router.navigate(['/auth/login']);
            })
        )
    },
        {
            dispatch: false
        })
}