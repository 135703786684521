import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { UtilityService } from './utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '../store/app.state';
import { Store } from '@ngrx/store';
import { Counts, TeamList } from './models/ss-team.model';

@Injectable({ providedIn: 'root' })
export class TeamService {
    teamBaseURL: string | undefined;
    roleBaseURL: string | undefined;
    userBaseURL: string | undefined;
    jobBaseUrl: string | undefined;
    constructor(
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private router: Router,
        private utility: UtilityService,
        private store: Store<AppState>
    ) {
        this.teamBaseURL = environment.team_base_url;
        this.jobBaseUrl = environment.job_base_url;
        this.roleBaseURL = environment.role_base_url;
        this.userBaseURL = environment.user_management_url;

    }

    getHeaderCount(): Observable<Counts[]> {
        return this.http.get<Counts>(`${this.teamBaseURL}team-member/get-team-member-count`)
            .pipe(map((counts: any) => {
                return counts.data
            }));
      }
    
    getSalesTeamList(): Observable<TeamList[]> {
      return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-sales-manager`)
          .pipe(map((data: any) => {
              const saleTeamList: TeamList[] = [];
              saleTeamList.push(data);
              return saleTeamList
          }));
    }

    getPreSalesAdminList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/pre-sales-admin`)
            .pipe(map((data: any) => {
                const saleTeamList: TeamList[] = [];
                saleTeamList.push(data);
                return saleTeamList
            }));
    }

    getPreSalesAssociateList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/pre-sales-associate`)
            .pipe(map((data: any) => {
                const saleTeamList: TeamList[] = [];
                saleTeamList.push(data);
                return saleTeamList
            }));
    }

    getSalesAssociateList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/sales-associate`)
            .pipe(map((data: any) => {
                const saleTeamList: TeamList[] = [];
                saleTeamList.push(data);
                return saleTeamList
            }));
    }

    getSalesPOCList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/sales-pocs`)
            .pipe(map((data: any) => {
                return data;
            }));
      }

    getTmtAdminList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-talent-partner`)
            .pipe(map((data: any) => {
                const tmtAdmin: TeamList[] = [];
;               tmtAdmin.push(data);
                return tmtAdmin;
            }));
    }

    getTmtAssociateList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-talent-partner-associate`)
            .pipe(map((data: any) => {
                const tmtTeamList: TeamList[] = [];
                tmtTeamList.push(data);
                return tmtTeamList
            }));
    }

    getAmAdminList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-account-manager`)
            .pipe(map((data: any) => {
                const amAdminTeamList: TeamList[] = [];
                amAdminTeamList.push(data);
                return amAdminTeamList
        }));
    }

    getCxAssociateList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-cx-associate`)
            .pipe(map((data: any) => {
                const cxTeamList: TeamList[] = [];
                cxTeamList.push(data);
                return cxTeamList
        }));
    }

    getHrList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-human-resources`)
            .pipe(map((data: any) => {
                const hrTeam: TeamList[] = [];
                hrTeam.push(data);
                return hrTeam
        }));
    }

    getCxAdminList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-cx-admin`)
            .pipe(map((data: any) => {
                const cxAdmin: TeamList[] = [];
                cxAdmin.push(data);
                return cxAdmin
        }));
    }


    getAmAssociateList(): Observable<TeamList[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-account-manager-associate`)
            .pipe(map((data: any) => {
                const amTeamList: TeamList[] = [];
                amTeamList.push(data);
                return amTeamList
            }));
    }

    getTeamRoleList(): Observable<any[]> {
        return this.http.get<TeamList>(`${this.roleBaseURL}roles`)
            .pipe(map((roleList: any) => {
                return roleList
        }));
    }

    getTeamMemberDetails(memberId: string): Observable<any[]> {
        return this.http.get<TeamList>(`${this.teamBaseURL}team-member/get-single-team-member/${this.utility.base64Encode(memberId)}`)
            .pipe(map((response: any) => {
                return response
        }));
    }

    addTeamMember(data: any) : Observable<any[]> {
        let memberDetails = Object.assign({}, data);
        delete memberDetails['roleDetails'];
        return this.http.post<any>(`${this.userBaseURL}team-member/addTeam`, memberDetails)
            .pipe(map((response : any) => {
                return response;
        }));     
    }

    addCoWorkerMember(coWorkerDetails: any, memberId: string) : Observable<any[]> {
        return this.http.post<any>(`${this.teamBaseURL}team-member/add-coworker/${this.utility.base64Encode(memberId)}`, coWorkerDetails)
            .pipe(map((response : any) => {
                return response;
        }));     
    }

    removeCoWorkerMember(memberId: string) : Observable<any[]> {
        return this.http.put<any>(`${this.teamBaseURL}team-member/remove-coworker/${this.utility.base64Encode(memberId)}`, '')
            .pipe(map((response : any) => {
                return response;
        }));     
    }


    updateTeamMember(data: any, memberId: string) : Observable<any[]> {
        let memberDetails = Object.assign({}, data);
        delete memberDetails['roleDetails'];
        return this.http.put<any>(`${this.userBaseURL}team-member/updateTeam/${this.utility.base64Encode(memberId)}`, memberDetails)
            .pipe(map((response : any) => {
                return response;
        }));     
    }

    udpateMemberStatus(memberId: string, status: any) : Observable<any[]> {
        return this.http.post<any>(`${this.userBaseURL}auth/change-status/${this.utility.base64Encode(memberId)}`, status)
            .pipe(map((response : any) => {
                return response;
        }));     
    }

    createPassword(memberId: string, value: any) : Observable<any[]> {
        return this.http.post<any>(`${this.userBaseURL}auth/create-password/${memberId}`, value)
            .pipe(map((response : any) => {
                return response;
        }));     
    }

    isCreatePasswordLinkExpired(memberId: string) : Observable<any[]> {
        return this.http.get<any>(`${this.userBaseURL}auth/check-password/${memberId}`)
            .pipe(map((response : any) => {
                return response;
        }));     
    }

    getTMTAssociateListForAssignJob() {
        return this.http.get<any>(`${this.teamBaseURL}team-member/talent-manager-associate?limit=200`)
    }

    getPreSalesAssociateListForAssignJob() {
        return this.http.get<any>(`${this.teamBaseURL}team-member/pre-sales-associate-members?limit=200`)
    }

    getSalesAssociateListForAssignJob() {
        return this.http.get<any>(`${this.teamBaseURL}team-member/sales-associate-members?limit=200`)
    }
    geAmAssignData() {
        return this.http.get<any>(`${this.teamBaseURL}team-member/am-associate-members`)
    }

    // get Client List
    getClientListForlist() {
    return this.http.get<any>(`${this.jobBaseUrl}clients/search-client`)
        .pipe(map((clientList: any) => {
            return clientList
        }));
}
  }