import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { AuthResponseData } from './models/auth-response.models';
import { User } from './models/user.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseURL :string;
  user_management_url_v2: string;
  constructor(
    private _http : HttpClient
  ) {
    this.baseURL = environment.developer_base_url;
    this.user_management_url_v2 = environment.user_management_url_v2;
  }
  // Login Form submition with the API call
  login(email :string, password:string) : Observable<HttpResponse<AuthResponseData>> {
    return this._http
      .post<AuthResponseData>(this.baseURL + 'login', {email, password,type:'admin'},
      {
        headers : new HttpHeaders().set('Content-Type', 'application/json'),
        observe : 'response'
      })
  }

  formateUser(data: any) {
    const user = new User(
      data.body.data.engineer?.first_name,
      data.body.data.engineer?.last_name,
      data.body.data.engineer?.email,
      data.headers.get('authorization'),
      // data.headers.get('x-amzn-remapped-authorization'),

      data.body.data.engineer?.role,
      data?.body.data.engineer?.profile_pic,
      data?.body.data.engineer?.user_id,
      data?.body.data.admin_sidebar
    );
    return user;
  }

  getErrorMessage(message:string){
    switch (message) {
      case 'Please enter valid Password':
        return 'Please enter valid credentials';
      case 'User not exist.':
        return 'Please enter valid credentials';
      default:
        return 'Unknown error occured. Please try again';
    }
  }

  setUserInLocalStorage(user : User, isTokenValid?: boolean) {
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('isLatestNewTokenValid', JSON.stringify(isTokenValid));
  };

  /**
   * Check validate token
   */
  isTokenValid(){
    const isTokenValid = localStorage.getItem('isLatestNewTokenValid');
    if(isTokenValid){
      const checkValidToken = JSON.parse(isTokenValid);
      return checkValidToken;
    }
    return null;
  }

  getUserFromLocalStorage() {
    const UserDataString = localStorage.getItem('userData');

    if(UserDataString) {
      const userData = JSON.parse(UserDataString);
      const user = new User (
        userData.first_name, userData.last_name,
        userData.email, userData.token,
        userData.role, userData.profilePic,
        userData.user_id,
        userData.permissions
      );
      return user;
    }
    return null;
  }

  logout(){
    localStorage.removeItem('userData');
    localStorage.removeItem('isNewTokenValid');
    localStorage.removeItem('isLatestNewTokenValid');
  }

  /**
   * leadPushNotification();
   * @param data
   * @description This function are called add lead notifications
   */
  leadPushNotification(data: any){
    return this._http.post(this.user_management_url_v2 + 'engineers/save-device-token', data);
  }

}
