import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import { BehaviorSubject, take } from 'rxjs';

import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging, private _auth: AuthService) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('../../../firebase-messaging-sw.js')
        .then(function (registration) {
          // console.log('Registration successful, scope is:', registration.scope);
        }).catch(function (err) {
          console.log('Service worker registration failed, error:', err);
        });
    }
  };

  async requestPermission() {
    if (firebase.messaging.isSupported()) {
      const permission = await Notification.requestPermission();
      // console.log('permission: ', permission);
      this.angularFireMessaging.requestToken.subscribe(
        (token: any) => {
          // console.log('token: ', token);

          this._auth.leadPushNotification({device_id: token}).pipe(take(1)).subscribe((success: any) => {
            this.listen();
          });
        },
        (err: any) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
    }
  };

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.currentMessage.next(payload);
      });
  };

  listen() {
    if (firebase.messaging.isSupported()) {
      this.angularFireMessaging.messages.subscribe((message: any) => {
        console.log('message: ', message);
        // let redirection = message.data.urlToRedirect;
        // var notificationData = (message.data);
        // var notificationOptions = {
        //   body: notificationData.body,
        //   click_action: redirection,
        //   data: {
        //     click_action: redirection
        //   } //seems necessary for firefox foreground clicks
        // };
        let redirection = message.data.urlToRedirect;
        var notificationData = message?.notification;
        var notificationOptions = {
          body: notificationData?.body,
          click_action: redirection,
          data: {
            click_action: redirection
          }
        };
        try {
          const title = notificationData?.title ? notificationData?.title : 'Supersourcing';
          var notification = new Notification(title, notificationData);
          notification.onclick = function (event) {
            event.preventDefault(); //prevent the browser from focusing the Notification's tab
            window.open(redirection, '_self');
            notification.close();
          }
        }
        catch (err) {
          console.log('err: ', err);
          try { //Need this part as on Android we can only display notifications thru the serviceworker
            navigator.serviceWorker.ready.then(function (registration) {
              registration.showNotification(notificationData?.title, notificationOptions);
            });
          } catch (err1) {
            console.log('err1: ', err1);
          }
        }
      })
    }
  };
}
