import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/services/error.handler.service';
import { TeamService } from 'src/app/core/services/team.service';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  togglePassword1: boolean = true;
  togglePassword2: boolean = true;
  createPasswordForm!: FormGroup<any>;
  userEmail!: string;
  constructor(
    private utility : UtilityService,
    private service : TeamService,
    private route : ActivatedRoute,
    private errorHandler : ErrorHandlerService,
    private router : Router,
    private _fb: FormBuilder
  ) {
    this._fb.group({

    })
    // this.createPasswordForm =  new FormGroup(
    //   {
    //   password : new FormControl('', [Validators.required, this.utility.validatePassword]),
    //   confirm : new FormControl('', [Validators.required])
    //   },
    //   { validators: this.utility.passwordMatch('password', 'confirm')}
    // );

    this.createPasswordForm = this._fb.group({
      password: ['', [Validators.required, this.utility.validatePassword]],
      confirm: ['', [Validators.required]]
    }, { validators: this.utility.passwordMatch('password', 'confirm')});
  };

  ngOnInit(): void {
    this.isLinkExpired();
  }

  createPassword(){
    if(this.createPasswordForm.invalid) {
      return
    }
    let password = {
      password : this.createPasswordForm.value.confirm
    }
    this.service.createPassword(this.route.snapshot.params['id'], password).subscribe({
      next: (res: any) => {
        if (res) {
          this.router.navigate(['/auth/login']);
        }
      },
      error: (error) => {
        this.errorHandler.routeAccordingToError(error);
      }
    });

  }

  isLinkExpired(){
      this.service.isCreatePasswordLinkExpired(this.route.snapshot.params['id']).subscribe({
        next: (res: any) => {
          if (res) {
            this.userEmail = res.data;
            if(res.statusCode != 200) {
              this.router.navigate(['/auth/login']);
            }
          }
        },
        error: (error) => {
          this.errorHandler.routeAccordingToError(error);
          console.log(error);
        }
      });
    }
}