import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../store/app.state';
import { getLoader } from '../../store/shared-state/shared.selector';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  showLoading! : Observable<boolean>;

  constructor(
    private store : Store<AppState>
  ) { }

  ngOnInit(): void {
    this.showLoading = this.store.select(getLoader);
  }

}