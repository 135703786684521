import { PanelMenuModule } from 'primeng/panelmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { NgModule } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import {TooltipModule} from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import {ChartModule} from 'primeng/chart';

const PRIMENG_MODULE = [
    MultiSelectModule,
    ButtonModule,
    DropdownModule,
    ChipModule,
    TooltipModule,
    PanelMenuModule,
    CalendarModule,ChartModule
]
@NgModule({
    declarations:[],
    imports:PRIMENG_MODULE,
    exports:PRIMENG_MODULE,
    providers:[]
})
export class PrimengModule{

}
