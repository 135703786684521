import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { exhaustMap, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { getToken } from '../store/auth-state/auth.selector';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  base = environment.assessment_base_url;
  awsS3Url: RegExp = /supersourcing-(video|doc|img)-(dev|prod).s3.ap-south-1.amazonaws.com/;
  constructor(
    private store : Store<AppState>
  ) {}

  intercept(
    request: HttpRequest<any>, 
    next: HttpHandler
  ): Observable<HttpEvent<any>>{
    if(!this.awsS3Url.test(request.url)) {
      return this.store.select(getToken).pipe(
        take(1),
        exhaustMap((token) =>{
        if(!token) {
          return next.handle(request);
        }
        let modifideReq = request.clone({
          setHeaders: {
            'Access-Control-Allow-Origin': '*',
            Authorization: token
          }
        })
        return next.handle(modifideReq);
      }))
    } else {

      let modifideReq = request.clone({
        setHeaders: {
          // 'Access-Control-Allow-Origin': '*'
        }
      })
      return next.handle(modifideReq);
    }
  }
}
