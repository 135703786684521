import { createReducer, on } from "@ngrx/store"
import { autoLogout, loginSuccess, loginSuccessAuto } from "./auth.actions";
import { initialState } from "./auth.state";

const _authReducer = createReducer(initialState,
    on(loginSuccess,(state: any, action: any) =>{
        return {
            ...state,
            user : action.user
        }
    }),
    on(loginSuccessAuto,(state: any, action: any) =>{
        return {
            ...state,
            user : action.user
        }
    }),

    
    on(autoLogout, state => {
        return {
            ...state,
            user: null
        }
    })
);

export function AuthReducer(state:any, action:any) {
    return _authReducer(state, action)
}

