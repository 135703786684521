<section class="login-signup" fxLayout="row" fxLayoutAlign="stretch">
    <div class="left-bar" fxHide.sm fxHide.xs>
           
    </div>
    <!-- Login Form  Start-->
    <mat-card class="individual-login-card right-bar mat-in-login">
        <div class="signup-form-box">
             <div fxLayout="row">
                <div fxLayout="column" class="head-desc pb-5">
                    <div class="mb-2 pb-1">
                        <a routerLink="/auth/login" class="bg-whisper p-3 border-radius-80 line-height-8 d-inline-block border-none">
                            <img src="assets/images/left-arrow.svg" alt="back icon" width="20" height="20" />
                        </a>
                    </div>
                    <h3 class="fs-30 fw-500 pvm m-0">Create new password</h3>
                    <p class="fs-14 fw-400 m-0">{{this.userEmail}}</p>
                </div>
            </div>
            <form [formGroup]="createPasswordForm" (ngSubmit)="createPassword()">
                <div fxLayout="column">
                    <mat-label>New password <span class="fs-12"> ( *Password cannot
                        contain whitespace )</span> </mat-label>
                    <mat-form-field class="input-full-width mbl" appearance="outline">
                        <input matInput [type]="togglePassword1 ? 'password' : 'text'" placeholder="Enter new password" formControlName="password">
                        <mat-hint class="fs-12">*Password must contain at least one special character, one number, one uppercase and a
                            lowercase letter and at least 6 characters</mat-hint>
                        <button type="button" mat-icon-button matSuffix (click)= "togglePassword1 = !togglePassword1"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]= "togglePassword1">
                            <mat-icon>{{togglePassword1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf= "createPasswordForm.controls['password'].hasError('required')">New password is required</mat-error>
                        <mat-error
                            *ngIf= "createPasswordForm.controls['password'].hasError('validatePassword') && !createPasswordForm.controls['password'].hasError('required')">
                            Password must contain at least one special character, one number, one uppercase and a
                            lowercase letter and at least 6 characters</mat-error>   
                    </mat-form-field>
                    
                    <mat-label>Confirm password</mat-label>
                    <mat-form-field class="input-full-width" appearance="outline">
                        <input matInput [type]="togglePassword2 ? 'password' : 'text'" placeholder="Confirm your password" formControlName="confirm">
                        <button type="button" mat-icon-button matSuffix (click)= "togglePassword2 = !togglePassword2"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]= "togglePassword2">
                            <mat-icon>{{togglePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf= "createPasswordForm.controls['confirm'].hasError('required')">Confirm password is required</mat-error>
                        <mat-error
                        *ngIf= "createPasswordForm.controls['confirm'].hasError('passwordMismatch')">
                        Passwords did not match</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="end end" class="mtm">
                    <button type="submit" class="btn-ribbon mat-signup" mat-button>Save new password</button>
                </div>
            </form>
        </div>
    </mat-card> 
    <!-- Login Form  End-->
</section>