import { Injectable } from '@angular/core';
// import jwt_decode from 'jwt-decode';
import { io } from "socket.io-client";
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { E } from '@angular/cdk/keycodes';


@Injectable({
  providedIn: 'root'
})
export class WebservicesService {
  base = environment.socket_base_url;
  userDetails!: any;


  /**notification  */
  private _notificationDataSource = new BehaviorSubject<any>({});
  public notificationData = this._notificationDataSource.asObservable();

  /** chat  */
  private _chatDataSource = new BehaviorSubject<any>([]);
  public chatData = this._chatDataSource.asObservable();
  /** quotation Inbox */

  private _quotationDataArray = new BehaviorSubject<any>([]);
  public quotationData = this._quotationDataArray.asObservable();

  /** inbox sidebar */
  private _chatListUpdate = new BehaviorSubject<any>([]);
  public chatSideListData = this._chatListUpdate.asObservable();


  private _chatListUpdateOneVendor = new BehaviorSubject<any>([]);
  public chatSideListDataOneVendor = this._chatListUpdateOneVendor.asObservable();

  /** inbox sidebar */
  private _chatListUpdate2 = new BehaviorSubject<any>([]);
  public chatSideListData2 = this._chatListUpdate2.asObservable();

  public viewMore$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _activatedRoute: ActivatedRoute,

  ) {
  }
  private socket!: any;

  connect() {

    const userDetails = JSON.parse(localStorage.getItem('userData') || '');
    const token = userDetails?.token.replace('Bearer ', '');  // GET local storage token
    this.socket = io(this.base + "?token=" + token); // Connect with socket


    let data = {
      token: userDetails?.token
    }

    this.socket.emit('checkNotification', JSON.stringify(data));
    // this.socket.emit('checkChatMessageAdmin', JSON.stringify(data));


    // this.socket.on('chatNotificationUpdateAdmin', (data:any) => {
    // });

    this.socket.on('notificationUpdate', (data: any) => {
      this._notificationDataSource.next(data);
    });



    this.socket.on('quotationUpdateInbox', (data: any) => {
      this._quotationDataArray.next(data);
    });


    this.socket.on('listenOneJobOneVendor', (data: any) => {
      this.viewMore$.value
      
      if (data.callEmit === 'getAdminChatList' && this._activatedRoute?.snapshot?.queryParams['vendor'] &&!this.viewMore$.value) {
        let vendor: any = JSON.parse(localStorage?.getItem('OneVendor') || '');
        // this.socket.emit("getAdminChatListWithoutData", JSON.stringify(data));
        
        
        this.socket.emit("getAdminChatList", JSON.stringify(vendor));
      }
      else if(this.viewMore$.value===true){
         
        let vendor: any = JSON.parse(localStorage?.getItem('OneVendor') || '');
         
        let data = {
          token: vendor?.token,
          project_id: vendor?.project_id,
          project_limit: 1,
          // vendor_limit: 1
        }
        
        this.socket.emit("getAdminChatList", JSON.stringify(data));
      }
      else
      {
        let vendor: any = JSON.parse(localStorage?.getItem('OneVendor') || '');
         
        let data = {
          token: vendor?.token,
          project_id: vendor?.project_id,
          project_limit: 1,
          vendor_limit: 1
        }
        
        this.socket.emit("getAdminChatList", JSON.stringify(data));
      }
    });

    this.socket.on('updateAdimSideBarChatList', (data: any) => {
      
      // setTimeout(() => {
      this._chatListUpdate.next(data);

      // }, 300);

      // }
    });

    this.socket.on('sendMessage', (data: any) => {
       
      this._chatDataSource.next(data);
    });

  }


  connectChatRoom(id?: string) {
    this.socket?.emit("connectChatRoom", id);
  }

  leaveChatRoom(id: string) {
    this.socket.emit("leave_room", id);
  }

  getClientChatList(selectedChatId: string, project_limit: number) {
    const userDetails = JSON.parse(localStorage.getItem('userData') || '');
    let data = {
      token: userDetails?.token,
      project_id: selectedChatId,
      project_limit: 1,
      vendor_limit: 1
    }
    localStorage.setItem("clientChatList", JSON.stringify(data));
    
    // this.socket.emit("getAdminChatList", JSON.stringify(data));
  }
  AdminForVendorOneProject(project_id: string, vendor_id: string, project_limit: number, vendor_limit: number) {

    const userDetails = JSON.parse(localStorage.getItem('userData') || '');

    let data = {
      token: userDetails?.token,
      project_id: project_id,
      vendor_id: vendor_id,
      project_limit: 1,
      vendor_limit: 1
    }
    
    localStorage.setItem("OneVendor", JSON.stringify(data));
    
    this.socket.emit("getAdminChatListWithoutData", JSON.stringify(data));


    // this.socket.emit("getAdminChatList", JSON.stringify(data));
    // this.socket.emit("updateSideBarAdminForOneProject", JSON.stringify(data));

  }

  disconnect() {
    this.socket.close();
    this.socket.disconnect();
  }
}
