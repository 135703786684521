import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HeaderComponent } from './components/header/header.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ProjectLayoutComponent } from './layouts/project-layout/project-layout.component';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TimeZonePipe } from './pipe/time-zone.pipe';
import { DayAgoPipe } from './pipe/day-ago.pipe';
import { TimeDurationPipe } from './pipe/time-duration.pipe';
import { TooltipListPipe } from './pipe/tooltip-list.pipe';
import { RemoveHyphenPipe } from './pipe/remove-hyphen.pipe';
import { UnderscoreToPipe } from './pipe/underscore-to.pipe';
import { NumberDirective } from './directives/number-input.directive';
import { DisableInputScrollDirective } from './directives/disable-input-scroll.directive';
import { INRPipe } from './pipe/inr.pipe';
import { inrCurrencyWithoutDecimal } from './pipe/inr-currency-without-decimal.pipe';
import { CapFirstLetterPipe } from './pipe/cap-first-letter.pipe';
import { FirstLettersPipe } from './pipe/firstLetters';
import { PriceConvertorPipe } from './pipe/price-convertor.pipe';
import { CurrencyFormatPipe } from './pipe/currency-format.pipe';
import { TimedifferencePipe } from './pipe/timedifference.pipe';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CounterTimerPipe } from './pipe/counter-timer.pipe';


@NgModule({
  declarations: [
    // ProjectLayoutComponent,
    HeaderComponent,
    AuthLayoutComponent,
    ProjectLayoutComponent,
    SideNavComponent,
    TimeZonePipe,
    DayAgoPipe,
    TimeDurationPipe,
    TooltipListPipe,
    UnderscoreToPipe,
    RemoveHyphenPipe,
    NumberDirective,
    DisableInputScrollDirective,
    INRPipe,
    inrCurrencyWithoutDecimal,
    CapFirstLetterPipe,
    FirstLettersPipe,
    PriceConvertorPipe,
    CurrencyFormatPipe,
    TimedifferencePipe,
    SafeUrlPipe,
    PaginatorComponent,
    CounterTimerPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    FlexLayoutModule,
    SideNavComponent,
    TimeZonePipe,
    DayAgoPipe,
    TimeDurationPipe,
    TooltipListPipe,
    UnderscoreToPipe,
    RemoveHyphenPipe,
    NumberDirective,
    DisableInputScrollDirective,
    INRPipe,
    inrCurrencyWithoutDecimal,
    CapFirstLetterPipe,
    FirstLettersPipe,
    ProjectLayoutComponent,
    PriceConvertorPipe,
    CurrencyFormatPipe,
    TimedifferencePipe,
    SafeUrlPipe,
    PaginatorComponent,
    CounterTimerPipe
  ],
  providers:[
    DatePipe
  ]
})
export class CoreModule { }
