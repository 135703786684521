<div class="bg-transparency-blue gabarito h-100vh">
  <div class="ss-logo">
    <img
      class="pll pvm prl"
      src="../../../../assets/images/supersourcing-logo.svg"
      width="240px"
      alt="Supersourcing Logo" />
  </div>
  <p-panelMenu [model]="filteredMenuList" class="p-sidenav" [multiple]="false"></p-panelMenu>
  <!-- <p-panelMenu [model]="menuList" class="p-sidenav" [multiple]="false"></p-panelMenu> -->
  <div class="logout-tab">
    <a class="text-d-none log-profile" routerLink="/user/profile"> 
      <div class="d-flex logout-profile bg-transparency-blue align-items-center color-charcoal">
        <img
          class="border-radius-80 me-2"
          width="30"
          height="30"
          [src]="profilePic ? profilePic : '../../../../assets/images/avatar-default.svg'"
          alt="User name and role" />
        <div class="fs-14 fw-500">
          {{ userName | titlecase }}
          <br />
          <span class="fs-13 fw-400">{{ role | titlecase }}</span>
        </div>
      </div>
    </a>
    <a class="text-d-none" (click)="logout($event)">
      <p class="d-flex logout-button bg-transparency-blue align-items-center">
        <img class="mrm" src="../../../../assets/images/icons/logout-icon.svg" alt="logout" />
        <span class="menu-title">Logout</span>
      </p>
    </a>
  </div>
</div>
