import { Routes } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";
import { TMAssociateRoleGuard } from "../guards/tmt.role.guard";

export const ALL_ROUTES: Routes = [
   
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'tmt-delegation',
        loadChildren: () => import('../../tmt-delegation/tmt-delegation.module').then(m => m.TmtDelegationModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'tmt',
        loadChildren: () => import('../../tmt-dashboard/tmt-dashboard.module').then(m => m.TmtDashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'individual-developer',
        loadChildren: () => import('../../individual-developer/individual-developer.module').then(m => m.IndividualDeveloperModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'hr-vetting',
        loadChildren: () => import('../../hr-vetting/hr-vetting.module').then(m => m.HrVettingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'job-management',
        loadChildren: () => import('../../job-management/job-management.module').then(m => m.JobManagementModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'clients',
        loadChildren: () => import('../../clients/clients.module').then(m => m.ClientsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'vendors',
        loadChildren: () => import('../../vendors/vendors.module').then(m => m.VendorsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'freelance',
        loadChildren: () => import('../../freelance-recuriter/freelance-recuriter.module').then(m => m.FreelanceRecuriterModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'ss-team',
        loadChildren: () => import('../../ss-team/ss-team.module').then(m => m.SsTeamModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'user',
        loadChildren: () => import('../../users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'chat',
        loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'notifications',
        loadChildren: () => import('../../notification/notification.module').then(m => m.NotificationModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'recommendations',
        loadChildren: () => import('../../recommendation-engine/recommendation-engine.module').then(m => m.RecommendationEngineModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'resume-builder',
        loadChildren: () => import('../../resume-builder/resume-builder.module').then(m => m.ResumeBuilderModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'hired-developer',
        loadChildren: () => import('../../hired-developer/hired-developer.module').then(m => m.HiredDeveloperModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'new-hired-developers',
        loadChildren: () => import('../../new-hired-developers/new-hired-developers.module').then(m => m.NewHiredDevelopersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'associate-delegation',
        loadChildren: () => import('../../associate-tmt-delegations/associate-tmt-delegations.module').then(m => m.AssociateTmtDelegationsModule),
        canActivate: [AuthGuard]
    },
]